// DEFINE EPICS
import { ofType } from "redux-observable";
import { catchError, filter, first, from, map, mergeMap } from "rxjs";
import {
  AUTHENTICATION_SUCCESS,
  GET_ALL_LISTS_SUCCESS,
  GET_ALL_TODOS_SUCCESS,
} from "../actions/actions";
import * as todoAPI from "../services/todoAPI";
import * as listAPI from "../services/listAPI";

export const getAllListsEpic = (action$, store) => {
  return action$.pipe(
    ofType(AUTHENTICATION_SUCCESS),
    mergeMap((action) => {
      return from(listAPI.fetchLists()).pipe(
        map((payload) => ({
          type: GET_ALL_LISTS_SUCCESS,
          payload: payload.response,
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR", message: err.message })
    )
  );
};

export const getAllTodosEpic = (action$, store) => {
  return action$.pipe(
    ofType(AUTHENTICATION_SUCCESS),
    mergeMap((action) => {
      return from(todoAPI.fetchTasks()).pipe(
        map((payload) => ({
          type: GET_ALL_TODOS_SUCCESS,
          payload: payload.response,
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR", message: err.message })
    )
  );
};
