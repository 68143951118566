import React from "react";
import { getWeekDayName, isToday } from "../commonHelpers/calendar-helper";

export function TodoListTitleCalendar(props) {
  const current_date = props.current_date ? props.current_date : null;

  return (
    <h2 className={`list-title ${isToday(current_date) ? "isToday" : ""}`}>
      <span>{getWeekDayName(current_date)}</span>
      <span className={"list-title-calendar-date"}>{current_date}</span>
    </h2>
  );
}
