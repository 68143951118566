import React, { useState } from "react";
import TodoList from "./todo-list";

export function Calendar() {
  const [weekToView, setWeekToView] = useState(0);

  function generateWeekToView(weekToView) {
    let dayToStartFrom = weekToView * 5;
    return [
      getTheDay(dayToStartFrom - 1),
      getTheDay(dayToStartFrom),
      getTheDay(dayToStartFrom + 1),
      getTheDay(dayToStartFrom + 2),
      getTheDay(dayToStartFrom + 3),
    ];
  }
  let weekViewCalendar = generateWeekToView(weekToView);

  //TODO: Move to common methods
  function getTheDay(i) {
    let newDay = new Date();
    newDay = new Date(newDay.setDate(newDay.getDate() + i));
    const month =
        "" + (newDay.getMonth() + 1) < 10
          ? "0" + (newDay.getMonth() + 1)
          : newDay.getMonth() + 1,
      day =
        "" + newDay.getDate() < 10 ? "0" + newDay.getDate() : newDay.getDate(),
      year = newDay.getFullYear();

    return [year, month, day].join("-");
  }

  function handlePrevWeek() {
    setWeekToView(weekToView - 1);
  }

  function handleNextWeek() {
    setWeekToView(weekToView + 1);
  }

  function handleTodaysWeek() {
    setWeekToView(0);
  }

  return (
    <div className={"calendar lists-container"}>
      <div className={"row-controls"}>
        <span className="material-icons md-48" onClick={handlePrevWeek}>
          arrow_back_ios
        </span>
        <span className="material-icons md-48" onClick={handleTodaysWeek}>
          home
        </span>
      </div>
      {weekViewCalendar.map((date, index) => (
        <TodoList current_date={date} />
      ))}
      <div className={"row-controls"}>
        <span className="material-icons" onClick={handleNextWeek}>
          arrow_forward_ios
        </span>
      </div>
    </div>
  );
}
