import React, { useState } from "react";
import { connect } from "react-redux";
import TodoTask from "./todo-task";
import { useInput } from "../hooks/input-hook";
import { ADD_TODO, MOVE_TODO } from "../actions/actions";
import TodoListTitle from "./todo-list-title";
import { isToday } from "../commonHelpers/calendar-helper";
import { TodoListTitleCalendar } from "./todo-list-title-calendar";

// TODO: Make list editable
// TODO: Make list sortable
function TodoList(props) {
  const { value, bind, reset } = useInput("");
  const [droppableActive, setDroppableActive] = useState(false);

  const list = props.list_id ? props.list : null;
  const todos = props.todos ? props.todos : [];
  //add constants for list type
  const listType = props.list_id ? "standard" : "calendar";

  const current_date = props.current_date ? props.current_date : null;
  const listTitle =
    listType === "standard" ? (
      <TodoListTitle list={list} current_date={props.current_date} />
    ) : (
      <TodoListTitleCalendar current_date={current_date} />
    );
  function handleSubmit(evt) {
    evt.preventDefault();
    const newTodo = {
      id: 0,
      description: value,
      is_done: false,
      list_id: listType === "standard" ? list.id : null,
      date: new Date(),
      current_date: listType === "calendar" ? props.current_date : null,
    };
    reset();

    console.log("handleSubmit", value, isToday(current_date));
    props.addTodo(newTodo);
  }

  function dragOverHandle(e) {
    e.preventDefault();

    setDroppableActive(true);
  }

  function dropHandle(e) {
    e.preventDefault();
    const todo = JSON.parse(e.dataTransfer.getData("todo"));
    setDroppableActive(false);

    props.moveTodo(todo, list ? list.id : null, current_date);
  }

  function dragLeaveHandle() {
    setDroppableActive(false);
  }

  function dragEndHandle() {
    setDroppableActive(false);
  }

  return (
    <div
      className={`list-container ${isToday(current_date) ? "isToday" : ""}`}
      key={props.key}
    >
      {/*TODO: Check if there is better practice for the below scenario - conditionally change rendered components*/}
      {listTitle}
      <div
        className={`list-content ${
          droppableActive ? "droppable-area-active" : ""
        }`}
        onDragOver={dragOverHandle}
        onDrop={dropHandle}
        onDragLeave={dragLeaveHandle}
        onDragEnd={dragEndHandle}
      >
        {todos.map((todo, index) => (
          <TodoTask todo={todo} key={todo.id + index} />
        ))}
        <form onSubmit={handleSubmit}>
          <input type="text" className={"list-new-item-input"} {...bind} />
        </form>
      </div>
    </div>
  );
}

function getTodosBylist_id(list_id, todos, ownProps) {
  return list_id
    ? todos.filter((todo) => todo.list_id === list_id)
    : todos.filter((todo) => todo.current_date === ownProps.current_date);
}

function mapStateToProps(state, ownProps) {
  const { lists } = state;
  const { list_id } = ownProps;

  return {
    todos: getTodosBylist_id(list_id, state.todos, ownProps),
    list: lists.filter((list) => list.id === list_id)[0],
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    addTodo: (todo) => dispatch({ type: ADD_TODO, todo }),
    moveTodo: (todo, list_id, current_date) =>
      dispatch({ type: MOVE_TODO, todo, list_id, current_date }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TodoList);
