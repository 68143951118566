import React from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import store from "../store/store";
import {
  ADD_TODO,
  AUTHENTICATION_SUCCESS,
  GET_ALL_LISTS,
  GET_ALL_TODOS,
  MOVE_TODO,
} from "../actions/actions";
import { connect } from "react-redux";

const Profile = (props) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  getAccessTokenSilently().then((token) => {
    document.cookie = "token=" + token;
    props.authenticationSuccess(token);
  });

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div className={"profile"}>
        {/*<img src={user.picture} alt={user.name} />*/}
        <small>{user.name}</small>
      </div>
    )
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticationSuccess: (token) =>
      dispatch({ type: AUTHENTICATION_SUCCESS, token }),
  };
};

export default connect(null, mapDispatchToProps)(Profile);
