// INIT STORE
import { createEpicMiddleware } from "redux-observable";
import { applyMiddleware, createStore } from "redux";
import { rootEpic } from "../epics/rootEpic";
import { rootReducer } from "../reducers/rootReducer";

const epicMiddleware = createEpicMiddleware();

function configureStore() {
  const store = createStore(rootReducer, applyMiddleware(epicMiddleware));

  // if (module.hot) {
  //     // Enable Webpack hot module replacement for reducers
  //     module.hot.accept('./reducers', () => {
  //         const nextRootReducer = require('./reducers').default;
  //         store.replaceReducer(nextRootReducer);
  //     });
  // }

  return store;
}

const store = configureStore();
epicMiddleware.run(rootEpic);

export default store;
