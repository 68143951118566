// DEFINE EPICS
import { ofType } from "redux-observable";
import { catchError, from, map, mergeMap } from "rxjs";
import {
  CREATE_NEW_LIST,
  CREATE_NEW_LIST_SUCCESS,
  DELETE_LIST,
  DELETE_LIST_SUCCESS,
  EDIT_LIST,
  EDIT_LIST_SUCCESS,
  GET_ALL_LISTS,
  GET_ALL_LISTS_SUCCESS,
} from "../actions/actions";
import * as listAPI from "../services/listAPI";

export const getAllListsEpic = (action$, store) => {
  return action$.pipe(
    ofType(GET_ALL_LISTS),
    mergeMap((action) => {
      return from(listAPI.fetchLists()).pipe(
        map((payload) => ({
          type: GET_ALL_LISTS_SUCCESS,
          payload: payload.response,
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR GET_ALL_LISTS", message: err.message })
    )
  );
};

export const editListEpic = (action$, store) => {
  return action$.pipe(
    ofType(EDIT_LIST),
    mergeMap((action) => {
      return from(listAPI.putList({ ...action.payload })).pipe(
        map((payload) => ({
          type: EDIT_LIST_SUCCESS,
          payload: { ...payload.response },
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR EDIT_LIST", message: err.message })
    )
  );
};

export const createListEpic = (action$, store) => {
  return action$.pipe(
    ofType(CREATE_NEW_LIST),
    mergeMap((action) => {
      return from(listAPI.postList({ ...action.payload })).pipe(
        map((payload) => ({
          type: CREATE_NEW_LIST_SUCCESS,
          payload: { ...payload.response },
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR CREATE_NEW_LIST", message: err.message })
    )
  );
};

export const deleteListEpic = (action$, store) => {
  return action$.pipe(
    ofType(DELETE_LIST),
    mergeMap((action) => {
      return from(listAPI.deleteList(action.payload.id)).pipe(
        map((payload) => {
          console.log("kakakakakaa", payload);
          return { type: DELETE_LIST_SUCCESS, payload: payload.response };
        })
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR CREATE_NEW_LIST", message: err.message })
    )
  );
};
