// DEFINE EPICS
import { ofType } from "redux-observable";
import { catchError, from, map, mergeMap } from "rxjs";
import {
  ADD_TODO,
  ADD_TODO_SUCCESS,
  DELETE_TODO,
  DELETE_TODO_SUCCESS,
  EDIT_TODO,
  EDIT_TODO_SUCCESS,
  GET_ALL_TODOS,
  GET_ALL_TODOS_SUCCESS,
  MOVE_TODO,
  MOVE_TODO_SUCCESS,
  TOGGLE_TODO,
  TOGGLE_TODO_SUCCESS,
} from "../actions/actions";
import * as api from "../services/todoAPI";

export const getAllTodosEpic = (action$, store) => {
  return action$.pipe(
    ofType(GET_ALL_TODOS),
    mergeMap((action) => {
      return from(api.fetchTasks()).pipe(
        map((payload) => ({
          type: GET_ALL_TODOS_SUCCESS,
          payload: payload.response,
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR GET_ALL_TODOS", message: err.message })
    )
  );
};

export const toggleTodo = (action$, store) => {
  return action$.pipe(
    ofType(TOGGLE_TODO),
    mergeMap((action) => {
      return from(
        api.putTodo({ ...action.payload, is_done: !action.payload.is_done })
      ).pipe(
        map((payload) => ({
          type: TOGGLE_TODO_SUCCESS,
          payload: payload.response,
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR TOGGLE_TODO", message: err.message })
    )
  );
};

export const editTodo = (action$, store) => {
  return action$.pipe(
    ofType(EDIT_TODO),
    mergeMap((action) => {
      return from(api.putTodo({ ...action.payload })).pipe(
        map((payload) => ({
          type: EDIT_TODO_SUCCESS,
          payload: payload.response,
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR TOGGLE_TODO", message: err.message })
    )
  );
};

export const moveTodo = (action$, store) => {
  return action$.pipe(
    ofType(MOVE_TODO),
    mergeMap((action) => {
      return from(
        api.putTodo({
          ...action.todo,
          list_id: action.list_id,
          current_date: action.current_date,
        })
      ).pipe(
        map((payload) => ({
          type: MOVE_TODO_SUCCESS,
          payload: payload.response,
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR TOGGLE_TODO", message: err.message })
    )
  );
};

export const createTodo = (action$, store) => {
  return action$.pipe(
    ofType(ADD_TODO),
    mergeMap((action) => {
      return api
        .createNewTask(action.todo)
        .pipe(
          map((payload) => ({
            type: ADD_TODO_SUCCESS,
            payload: payload.response,
          }))
        );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR createTodo", message: err.message })
    )
  );
};

export const deleteTodo = (action$, store) => {
  return action$.pipe(
    ofType(DELETE_TODO),
    mergeMap((action) => {
      return from(api.deleteTodo(action.payload)).pipe(
        map((payload) => ({
          type: DELETE_TODO_SUCCESS,
          payload: payload.response,
        }))
      );
    }),
    catchError((err) =>
      Promise.resolve({ type: "ERROR deleteTodo", message: err.message })
    )
  );
};
