import { combineEpics } from "redux-observable";
import * as todosEpics from "./todosEpic";
import * as listsEpics from "./listsEpic";
import * as initEpics from "./initEpic";
import { useAuth0 } from "@auth0/auth0-react";

export const rootEpic = combineEpics(
  initEpics.getAllListsEpic,
  initEpics.getAllTodosEpic,

  todosEpics.getAllTodosEpic,
  todosEpics.createTodo,
  todosEpics.toggleTodo,
  todosEpics.moveTodo,
  todosEpics.deleteTodo,

  listsEpics.getAllListsEpic,
  listsEpics.editListEpic,
  listsEpics.createListEpic,
  listsEpics.deleteListEpic
);
