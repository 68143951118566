import { combineReducers } from "redux";
import todosReducer from "./todosReducer";
import listsReducer from "./listsReducer";

const appReducer = combineReducers({
  todos: todosReducer,
  lists: listsReducer,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
