import React from "react";
import { connect } from "react-redux";
import { deleteTodo } from "../epics/todosEpic";

function TodoTask(props) {
  const todo = props.todo;
  const toggleTodo = props.toggleTodo;

  function dragStartHandle(e) {
    e.dataTransfer.setData("application/my-app", todo.id);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("todo", JSON.stringify(todo));
  }

  function handleTaskClick() {
    toggleTodo(todo);
  }

  function handleDelete() {
    props.deleteTodo(todo.id);
  }

  return (
    <div
      key={todo.id}
      draggable={"true"}
      className={`task ${todo.is_done ? "done" : ""}`}
      onDragStart={dragStartHandle}
    >
      <span className={`task-description`} onClick={handleTaskClick}>
        {todo.description}
      </span>
      <span className={`task-controls`}>
        {/*<span className={"edit-button"} onClick={handleEdit}>*/}
        {/*    E*/}
        {/*</span>*/}
        <span className={"delete-button"} onClick={handleDelete}>
          <span className="material-icons">delete</span>
        </span>
      </span>
    </div>
  );
}

// function mapStateToProps(state, ownProps) {
// const {lists} = state;
// const {list_id} = ownProps;
//
// return {
//     todos: getTodosBylist_id(list_id, state.todos),
//     list: lists.filter(list => list.id === list_id)[0]
// }
// }

function mapDispatchToProps(dispatch) {
  return {
    toggleTodo: (todo) => dispatch({ type: "TOGGLE_TODO", payload: todo }),
    deleteTodo: (todoId) => dispatch({ type: "DELETE_TODO", payload: todoId }),
  };
}

export default connect(null, mapDispatchToProps)(TodoTask);
