import { ajax } from "rxjs/ajax";
import { getCookie } from "../commonHelpers/common-functions";
let httpConfig = {
  "Content-Type": "application/json",
};

const API_KEY = process.env.REACT_APP_API_BASE_URL;
const apiUrl = API_KEY + '/todoTasks/';

function getConfig() {
  return { ...httpConfig, Authorization: `Bearer ${getCookie("token")}` };
}

export function fetchTasks() {
  return ajax.get(apiUrl, getConfig());
}

export function putTodo(todo) {
  return ajax.put(apiUrl + todo.id, todo, getConfig());
}

export function createNewTask(task) {
  return ajax.post(apiUrl, task, getConfig());
}

export function deleteTodo(taskId) {
  return ajax.delete(apiUrl + taskId, getConfig());
}
