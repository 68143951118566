import "./App.scss";
import { connect } from "react-redux";
import TodoList from "./components/todo-list";
import logo from "./assets/logo.svg";
import { Calendar } from "./components/calendar";
import React, { useState } from "react";
import LoginButton from "./components/loginButton";
import Profile from "./components/profile";
import { useAuth0 } from "@auth0/auth0-react";
import { CREATE_NEW_LIST } from "./actions/actions";

function User() {
  return null;
}

// DEFINE COMPONENT
function App(props) {
  const { isAuthenticated } = useAuth0();
  const [customListPositionView, setCustomListPositionView] = useState(0);
  let currentListsViewed = props.lists
    .sort((a, b) => (a.id > b.id ? 1 : -1))
    .slice(customListPositionView, customListPositionView + 5);

  if (!isAuthenticated) {
    return (
      <div className={"loginPage"}>
        <div className="logo">
          <img src={logo} className="App-logo" alt="logo" />
          <span>:Todos</span>
        </div>
        <LoginButton />
      </div>
    );
  }

  function handleAddList() {
    props.createNewList();
  }

  function handleNextListPosition() {
    if (props.lists.length >= customListPositionView + 5) {
      setCustomListPositionView(customListPositionView + 5);
    }
  }
  function handlePrevListPosition() {
    if (customListPositionView - 5 >= 0) {
      setCustomListPositionView(customListPositionView - 5);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">
          <img src={logo} className="App-logo" alt="logo" />
          <span>:Todos</span>
          <User />
        </div>
        <div className="header-menu">{isAuthenticated && <Profile />}</div>
      </header>
      <Calendar />
      <div className={"mid-screen-divider"}>
        {/*<span className="material-icons">*/}
        {/*  rocket_launch*/}
        {/*  </span>*/}
      </div>
      <div className={"lists-container"}>
        <div className={"row-controls"}>
          <span className="material-icons" onClick={handlePrevListPosition}>
            arrow_back_ios
          </span>
        </div>
        {currentListsViewed.map((list, index) => {
          return <TodoList list_id={list.id} key={list.id + "-" + index} />;
        })}
        <div className={"row-controls"}>
          <span className="material-icons" onClick={handleNextListPosition}>
            arrow_forward_ios
          </span>
          <span className="material-icons" onClick={handleAddList}>
            add
          </span>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    lists: state.lists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewList: () =>
      dispatch({ type: CREATE_NEW_LIST, payload: { title: "Brand new list" } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

// TODO: Convert to TS
// TODO: Convert to JSS
// TODO: Add Jest / testing
// TODO: Dark theme/s
// TODO: Authentication Auth0
// TODO: Admin app
