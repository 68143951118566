import React, { useState } from "react";
import { connect } from "react-redux";
import { DELETE_LIST, EDIT_LIST } from "../actions/actions";
import { getWeekDayName, isToday } from "../commonHelpers/calendar-helper";
import { useInput } from "../hooks/input-hook";

function TodoListTitle(props) {
  const list = props.list?.id ? props.list : null;
  const listType = list?.id ? "standard" : "calendar";
  const current_date = props.current_date ? props.current_date : null;
  const { value, bind, reset } = useInput("");
  const [editMode, setEditMode] = useState(false);
  const editForm = (
    <form onSubmit={handleSubmit}>
      <input type="text" className={"edit-title-input"} {...bind} />
    </form>
  );
  const normalMode = (
    <span>
      {list.title}
      <span className={"list-title-controls"}>
        <span className={"edit-button"} onClick={handleEditButtonClick}>
          <span className="material-icons">edit</span>
        </span>
        <span className={"delete-button"} onClick={handleEditButtonClick}>
          <span className="material-icons">delete</span>
        </span>
      </span>
    </span>
  );
  function handleSubmit(evt) {
    evt.preventDefault();
    const editedList = { ...list, title: value };
    reset();
    setEditMode(false);
    props.editListTitle(editedList);
  }

  function handleEditButtonClick() {
    setEditMode(!editMode);
  }

  function handleDeleteButtonClick() {
    //TODO: Implement confirmation
    props.deleteList(list);
  }

  if (listType === "standard") {
    return (
      <h2 className={"list-title"}>
        {editMode ? editForm : ""}
        {!editMode ? normalMode : ""}
      </h2>
    );
  } else {
    return (
      <h2 className={isToday(current_date) ? "isToday" : ""}>
        <span>{getWeekDayName(current_date)}</span>
        <span>{current_date}</span>
      </h2>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editListTitle: (list) => dispatch({ type: EDIT_LIST, payload: list }),
    deleteList: (list) => dispatch({ type: DELETE_LIST, payload: list }),
  };
};

export default connect(null, mapDispatchToProps)(TodoListTitle);
