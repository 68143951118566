export const ADD_TODO = "ADD_TODO";
export const ADD_TODO_SUCCESS = "ADD_TODO_SUCCESS";
export const RECEIVED_TODO = "RECEIVE_TODO";
export const TOGGLE_TODO = "TOGGLE_TODO";
export const TOGGLE_TODO_SUCCESS = "TOGGLE_TODO_SUCCESS";
export const EDIT_TODO = "EDIT_TODO";
export const EDIT_TODO_SUCCESS = "EDIT_TODO_SUCCESS";
export const DELETE_TODO = "DELETE_TODO";
export const DELETE_TODO_SUCCESS = "DELETE_TODO_SUCCESS";
export const MOVE_TODO = "MOVE_TODO";
export const MOVE_TODO_SUCCESS = "MOVE_TODO_SUCCESS";
export const GET_ALL_TODOS = "GET_ALL_TODOS";
export const GET_ALL_TODOS_SUCCESS = "GET_ALL_TODOS_SUCCESS";

export const CREATE_NEW_LIST = "CREATE_NEW_LIST";
export const CREATE_NEW_LIST_SUCCESS = "CREATE_NEW_LIST_SUCCESS";
export const EDIT_LIST = "EDIT_LIST";
export const EDIT_LIST_SUCCESS = "EDIT_LIST_SUCCESS";
export const DELETE_LIST = "DELETE_LIST";
export const DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS";
export const GET_ALL_LISTS = "GET_ALL_LISTS";
export const GET_ALL_LISTS_SUCCESS = "GET_ALL_LISTS_SUCCESS";

export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
