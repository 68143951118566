import * as appActions from "../actions/actions";

const todosReducer = (state = [], action) => {
  console.log("REDUCER todos", state, action);
  switch (action.type) {
    case appActions.GET_ALL_TODOS_SUCCESS:
      console.log("GET_ALL_TODOS_SUCCESS", action.payload);
      return [...action.payload];
    case appActions.ADD_TODO_SUCCESS:
      console.log("ADD_TODO_SUCCESS", action.payload);
      return [...state, action.payload];
    case appActions.TOGGLE_TODO_SUCCESS:
    case appActions.EDIT_TODO_SUCCESS:
    case appActions.MOVE_TODO_SUCCESS:
      console.log("UPDATED TODO", action.payload);
      return [
        ...state.map((todo) =>
          todo.id === action.payload.id ? action.payload : todo
        ),
      ];
    case appActions.DELETE_TODO_SUCCESS:
      console.log("DELETE_TODO_SUCCESS", action.payload);
      return [...state.filter((todo) => todo.id !== action.payload)];
    default:
      return state;
  }
};

export default todosReducer;
