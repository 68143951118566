import { ajax } from "rxjs/ajax";
import { getCookie } from "../commonHelpers/common-functions";
let httpConfig = {
  "Content-Type": "application/json",
};

const API_KEY = process.env.REACT_APP_API_BASE_URL;
const todoListsUrl = API_KEY + '/todoLists/';

function getConfig() {
  return { ...httpConfig, Authorization: `Bearer ${getCookie("token")}` };
}

export function fetchLists() {
  return ajax.get(todoListsUrl, getConfig());
}

export function putList(list) {
  return ajax.put(todoListsUrl + list.id, list, getConfig());
}

export function postList(list) {
  return ajax.post(todoListsUrl, list, getConfig());
}

export function deleteList(listId) {
  return ajax.delete(todoListsUrl + listId, getConfig());
}
