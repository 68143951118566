import * as appActions from "../actions/actions";

const listsReducer = (state = [], action) => {
  console.log("REDUCER lists", state, action);
  switch (action.type) {
    case appActions.GET_ALL_LISTS_SUCCESS:
      return [...action.payload];
    case appActions.EDIT_LIST_SUCCESS:
      return [
        ...state.map((list) =>
          list.id === action.payload.id ? action.payload : list
        ),
      ];
    case appActions.CREATE_NEW_LIST_SUCCESS:
      return [...state, action.payload];
    case appActions.DELETE_LIST_SUCCESS:
      return [...state.filter((list) => list.id !== action.payload)];
    default:
      return state;
  }
};

export default listsReducer;
